import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ApiService,
  ExtendedApiResponse,
  StorageService,
  SupportedCoinsRequest,
  SupportedCoinsResponse,
} from '@core';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '@auth/services';
import { SupportedCoinDto } from '../../../../../coin-depo-lib/dots/supported-coin.dto';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CoinsService {
  protected storageKey = 'supportedCoins';
  protected supportedCoins$ = new BehaviorSubject<SupportedCoinDto[]>(
    this.storage.get(this.storageKey, [])
  );
  cachedSupportedCoins$ = this.supportedCoins$
    .asObservable()
    .pipe(
      map((coins) =>
        coins
          .filter((coin) => coin.is_active === 1)
          .sort((a, b) => a.order - b.order)
      )
    );

  allCachedSupportedCoins$ = this.supportedCoins$.asObservable();

  constructor(
    protected balancesApi: ApiService,
    protected auth: AuthService,
    protected storage: StorageService
  ) {
    console.log(this.storageKey, this.storage.get(this.storageKey, []));
  }

  updateSupportedCoins(): Observable<
    ExtendedApiResponse<SupportedCoinsResponse>
  > {
    const userData = this.auth.userData;

    const data: SupportedCoinsRequest = {
      client_token: this.auth.accessToken,
      username: userData.email,
    };

    return this.balancesApi.getSupportedCoins(data).pipe(
      tap((res: ExtendedApiResponse<SupportedCoinsResponse>) => {
        this.supportedCoins$.next(res.coin_list);
        this.storage.set(this.storageKey, res.coin_list);
      })
    );
  }

  getSupportedCoinsSnapshot(): SupportedCoinDto[] {
    return this.supportedCoins$.value;
  }

  getSupportedCoinsByTickerSnapshot(
    ticker: string
  ): SupportedCoinDto | undefined {
    return this.supportedCoins$.value.find(
      (coin) => coin.binance_ticker === ticker
    );
  }
  getSupportedCoinsByNameSnapshot(name: string): SupportedCoinDto | undefined {
    return this.supportedCoins$.value.find((coin) => coin.coin_name === name);
  }
}
