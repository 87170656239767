import { isArray, isNumber, isString } from 'lodash';

export const toFormData = (data: Record<string, any>): FormData =>
  Object.entries(data).reduce(
    (acc: FormData, [key, value]: [string, any | any[]]) => {
      if (value || isNumber(value) || isString(value)) {
        if (isArray(value) && value.length) {
          value.forEach((val) => acc.append(`${key}[]`, val));
        } else {
          acc.append(key, value);
        }
      }

      return acc;
    },
    new FormData()
  );
