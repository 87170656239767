/* eslint-disable no-underscore-dangle */
import { NAVIGATOR } from '@ng-web-apis/common';
import { get, set } from 'lodash';
import moment from 'moment';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { UsersApiService } from '@core/http';

import {
  ApiResponse,
  ConfirmationCodeRequest,
  ExtendedApiResponse,
  LanguageType,
  LoginApiResponse,
  LoginRequest,
  RegisterApiResponse,
  RegisterRequest,
  ResetPasswordRequest,
  User,
  UserProfileInfo,
  UserService,
  WaitListRequest,
  WaitListResponse,
} from '@core/interfaces';

import { StorageService, ToastService } from '@core/services';
import { environment } from '@env/environment';
import { ChangePasswordRequest } from '@interfaces/api/requests/change-password-request';
import { UserTypeService } from '@services/user-type.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  static totpResendCodeMin = 10;
  authChanged = new BehaviorSubject<boolean>(false);
  currentUser: UserProfileInfo;
  isRefreshing = false;
  parentId = 'D-122112';

  private destroyed$ = new Subject<void>();
  private broadcastLogout = new BroadcastChannel('cd-logout-channel');

  constructor(
    @Inject(NAVIGATOR) private navigator: Navigator,
    private router: Router,
    private cookies: CookieService,
    private toast: ToastService,
    private userApi: UsersApiService,
    private storage: StorageService,
    private userTypeService: UserTypeService,
    private translate: TranslateService
  ) {
    this.currentUser = new UserProfileInfo(new UserService(this.storage));

    this.checkAuth().then((res) => {
      if (res) {
        this.currentUser[this.isLoggedIn ? 'load' : 'clear']();
      }
    });


    this.currentUser.sLoaded$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: boolean | null): void => {
        if (res) {
          this.authChanged.next(true);
        }
      });
    this.logoutBroadcastEventInit();
  }

  get showDashboardBanner(): boolean {
    return this.storage.get('showDashboardBanner', true);
  }

  set showDashboardBanner(val: boolean) {
    this.storage.set('showDashboardBanner', val);
  }

  get isLoggedIn(): boolean {
    // const token = this.getToken('access_token');
    // return !this.isExpired && Boolean(token);
    const data: User = this.userData;
    const token = this.accessToken;
    return !!data && !!token;
  }

  get isExpired(): boolean {
    // const expDate = this.expireDate;
    // let isExpired = false;

    // if (expDate) {
    //   isExpired = moment().utc().isAfter(expDate);
    // }

    // return isExpired;
    return false;
  }

  get accessToken(): string {
    return this.cookies.get('accessToken');
  }

  set accessToken(val: string) {
    const expires = moment().add(35, 'days').toDate();

    this.cookies.put('accessToken', val, {
      expires,
      secure: environment.production,
      sameSite: 'strict',
    });

    this.cookies.put('tokenExpiration', expires.toUTCString(), {
      expires: moment().add(35, 'days').toDate(),
      secure: environment.production,
      sameSite: 'strict',
    });
  }

  get userData(): User {
    return this.storage.get('userData') as User;
  }

  set userData(data: Partial<User>) {
    this.storage.set('userData', data);
  }

  get resendCodeCnt(): number {
    const cnt = parseInt(this.cookies.get('resendCodeCnt'), 10);

    if (Number.isNaN(cnt)) {
      return 0;
    }

    return cnt;
  }

  set resendCodeCnt(value: number) {
    const expires = moment()
      .add(AuthService.totpResendCodeMin, 'minutes')
      .toDate();

    this.cookies.put('resendCodeCnt', value.toString(10), {
      expires,
      secure: environment.production,
      sameSite: 'strict',
    });

    this.cookies.put('resendCodeCntExpiration', expires.toUTCString(), {
      expires: moment()
        .add(AuthService.totpResendCodeMin, 'minutes')
        .unix()
        .toString(),
      secure: environment.production,
      sameSite: 'strict',
    });
  }

  get totpCnt(): number {
    const cnt = parseInt(this.cookies.get('totpCnt'), 10);

    if (Number.isNaN(cnt)) {
      return 0;
    }

    return cnt;
  }

  set totpCnt(value: number) {
    const expires = moment()
      .add(AuthService.totpResendCodeMin, 'minutes')
      .toDate();

    this.cookies.put('totpCnt', value.toString(10), {
      expires,
      secure: environment.production,
      sameSite: 'strict',
    });
    if (value >= 3) {
      this.cookies.put('totpCntExpiration', expires.toUTCString(), {
        expires: moment()
          .add(AuthService.totpResendCodeMin, 'minutes')
          .toDate(),
        secure: environment.production,
        sameSite: 'strict',
      });
    }
  }

  get waitListId(): number {
    return this.storage.get('waitListId');
  }

  set waitListId(val: number) {
    this.storage.set('waitListId', val);
  }

  get totpActivated(): boolean {
    return this.storage.get('totpActivated');
  }

  set totpActivated(val: boolean) {
    this.storage.set('totpActivated', val);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  tokenExpiration(left = false): string | Date | null {
    const cookie = this.cookies.get('tokenExpiration');

    if (cookie) {
      const expiry = moment.utc(cookie);

      if (left) {
        return expiry.to(moment.utc());
      } else {
        return expiry.local().toDate();
      }
    }

    return null;
  }

  totpCntExpiration(left = false): string | Date | null {
    const cookie = this.cookies.get('totpCntExpiration');

    if (cookie) {
      const expiry = moment.utc(cookie);

      if (left) {
        return expiry.to(moment.utc());
      } else {
        return expiry.local().toDate();
      }
    }

    return null;
  }

  verifyTOTP(totp: string, request_id: string) {
    const data = {
      client_token: this.accessToken,
      username: this.userData.username,
      totp,
      browser: this.navigator.userAgent,
      request_id,
    };
    return this.userApi.addTOTP(data);
  }

  delTOTP(totp: string) {
    const data = {
      client_token: this.accessToken,
      username: this.userData.username,
      browser: this.navigator.userAgent,
      totp,
    };
    return this.userApi.delTOTP(data);
  }

  deleteUser(totp: string) {
    const data = {
      client_token: this.accessToken,
      username: this.userData.username,
      totp,
    };
    return this.userApi.deleteUser(data);
  }

  getLastIps(appendData: any = {}) {
    const data = {
      client_token: this.accessToken,
      username: this.userData.username,
      ...appendData,
    };
    return this.userApi.getLastIps(data);
  }

  addTOTP(request_id: string) {
    const data = {
      client_token: this.accessToken,
      username: this.userData.username,
      browser: this.navigator.userAgent,
      request_id,
    };
    return this.userApi.addTOTP(data);
  }

  resendCodeCntExpiration(left = false): string | Date | null {
    const cookie = this.cookies.get('resendCodeCntExpiration');

    if (cookie) {
      const expiry = moment.utc(cookie);

      if (left) {
        return expiry.to(moment.utc());
      } else {
        return expiry.local().toDate();
      }
    }

    return null;
  }

  getUserParam(key: string) {
    const stringified = localStorage.getItem('userParams');
    const userParams = stringified ? JSON.parse(stringified) : {};
    return get(userParams, key, undefined);
  }

  setUserParam(key: string, value: unknown) {
    const stringified = localStorage.getItem('userParams');
    const userParams = stringified ? JSON.parse(stringified) : {};
    set(userParams, key, value);
    localStorage.setItem('userParams', JSON.stringify(userParams));
  }

  clearResendCnt(): void {
    this.cookies.remove('resendCodeCnt');
    this.cookies.remove('resendCodeCntExpiration');
  }

  clearTotpCnt(): void {
    this.cookies.remove('totpCnt');
    this.cookies.remove('totpCntExpiration');
  }

  sendEmailVerificationCode(email: string, lang: LanguageType) {
    return this.userApi.sendEmailVerificationCode({
      username: email,
      lang,
    });
  }

  login(
    data: Partial<LoginRequest>
  ): Observable<ExtendedApiResponse<LoginApiResponse>> {
    return this.userApi
      .login({
        ...data,
        username: data.email,
        browser: this.navigator.userAgent,
        parent_id: this.parentId,
        no_secure_check: data.no_secure_check ? 1 : 0,
        lang: this.translate.currentLang,
        id: localStorage.getItem('click_id') || '',
        ga_utm: localStorage.getItem('ga_utm') || '',
      } as LoginRequest)
      .pipe(
        map(
          (
            res: ExtendedApiResponse<LoginApiResponse>
          ): { user: User; token: string } | any => {
            console.log(res);
            if (res.status === 'success') {
              if (localStorage.getItem('click_id')) {
                localStorage.removeItem('click_id');
              }
              if (localStorage.getItem('ga_utm')) {
                localStorage.removeItem('ga_utm');
              }
              const email = data.email as string;
              this.userTypeService.setUserType(res.user_type);
              return {
                user: {
                  id: res.user_id,
                  username: email,
                  email,
                },
                token: res.token,
              };
            } else {
              return res;
            }
          }
        ),
        catchError((err: HttpErrorResponse) => of(err.error)),
        tap((res: { user: User; token: string } | any) => {
          if (res && typeof res !== 'string') {
            this.userData = res.user;
            this.accessToken = res.token;
            this.currentUser.load();
          }
        })
      );
  }

  changePasswordRequest(
    password: string
  ): Observable<ExtendedApiResponse<any>> {
    const data = {
      client_token: this.accessToken,
      password,
    };
    return this.userApi.changePasswordRequest(data).pipe(
      tap(({ status, token }) => {
        if (status === 'success') {
          this.accessToken = token;
        }
      })
    );
  }

  changePasswordConfirmation(
    secret: string,
    new_password: string
  ): Observable<ExtendedApiResponse<any>> {
    const data = {
      client_token: this.accessToken,
      username: this.userData.username,
      secret,
      new_password,
    };
    return this.userApi.changePasswordConfirmation(data);
  }

  logoutBroadcastEventInit() {
    this.broadcastLogout.addEventListener('message', () => {
      this.clear();
      this.currentUser.clear();
      this.authChanged.next(false);
      void this.router.navigate(['/auth/login']);
    });
  }

  logout(redirect = true, backend = true, onLogOut?: () => void): void {
    const clear = () => {
      this.clear();
      this.currentUser.clear();
      this.authChanged.next(false);
    };
    if (backend) {
      const data = this.userData;
      this.userApi
        .logout({
          username: data.email as string,
          client_token: this.accessToken,
        })
        .subscribe({
          next: (res: ApiResponse) => {
            if (res.status === 'success') {
              onLogOut?.();
              clear();
              if (redirect) {
                void this.router.navigate(['/auth/login']);
              }
            }
          },
        });
    } else {
      onLogOut?.();
      clear();
      if (redirect) {
        void this.router.navigate(['/auth/login']);
      }
    }
    this.broadcastLogout.postMessage({ redirect: true, backend: false });
  }

  register(
    data: RegisterRequest
  ): Observable<ExtendedApiResponse<RegisterApiResponse>> {
    return this.userApi.register({
      ...data,
      lang: this.translate.currentLang,
      id: localStorage.getItem('click_id') || '',
      ga_utm: localStorage.getItem('ga_utm') || '',
    });
  }

  verifyBonusCode(
    data: {['referral']: string}
  ): Observable<ApiResponse> {
    return this.userApi.verifyBonusCode(data);
  }

  getConfirmationCode(
    data?: ConfirmationCodeRequest
  ): Observable<boolean | string> {
    if (this.userData) {
      const username = this.userData.username || this.userData.email;
      data = data || { client_token: '', username, action: 'register', lang: this.translate.currentLang as LanguageType };
    }

    return this.userApi
      .getConfirmationCode(data as ConfirmationCodeRequest)
      .pipe(
        map((res: ApiResponse): boolean | string => {
          if (res.status === 'success') {
            return true;
          } else {
            return res.msg;
          }
        }),
        catchError((err: HttpErrorResponse) => of(err.error.msg))
      );
  }

  verifyEmail(secret: number, lang: string): Observable<ApiResponse> {
    const data = this.userData;
    if (data && (data.username || data.email)) {
      return this.userApi.verifyEmail({
        confirmation_code: secret,
        username: data.username || (data.email as string),
        lang,
        id: localStorage.getItem('click_id') || '',
        ga_utm: localStorage.getItem('ga_utm') || '',
      });
    } else {
      return of({
        status: 'error',
        msg: 'No user data found!',
        code: 'NO_USER_DATA',
      } as ApiResponse);
    }
  }

  resetPassword(data: ResetPasswordRequest): Observable<boolean | string> {
    return this.userApi.resetPassword(data).pipe(
      map((res: ApiResponse): boolean | string => {
        if (res.status === 'success') {
          return true;
        } else {
          return res.msg;
        }
      }),
      catchError((err: HttpErrorResponse) => of(err.error.msg))
    );
  }

  setPassword(
    newPassword: string,
    code: number,
    check = false
  ): Observable<boolean | string> {
    const data = {
      username: this.userData.email as string,
      password: newPassword,
      secret: code,
      check: check ? 1 : null,
    };

    return this.userApi.resetPassword(data).pipe(
      map((res: ApiResponse): boolean | string => {
        if (res.status === 'error') {
          return res.msg;
        }

        return true;
      }),
      catchError((err: HttpErrorResponse) => of(err.error.msg))
    );
  }

  changePassword(new_password: string, secret: string) {
    const data: ChangePasswordRequest = {
      username: this.userData.email as string,
      client_token: this.accessToken,
      new_password,
      secret,
      totp: 0,
    };

    return this.userApi.changePassword(data);
  }

  waitListManage(create = false): Observable<number | string> {
    const client_token = this.accessToken;

    if (client_token) {
      const userData = this.currentUser.data$.getValue() as User;

      const data: WaitListRequest = {
        username: userData.username || (userData.email as string),
        client_token,
        status: create ? 1 : 2,
        user_id: userData.id,
      };

      return this.userApi.waitListManage(data).pipe(
        map((res: ExtendedApiResponse<WaitListResponse>): number | string => {
          if (res.status === 'error') {
            return res.msg;
          }

          return res.waitlist_id;
        }),
        catchError((err: HttpErrorResponse) => of(err.error.msg))
      );
    }

    return of('Error while POST to /api/join_waitlist');
  }

  private async checkAuth(): Promise<boolean> {
    // const expire = this.isTokenExpired();
    this.authChanged.next(this.isLoggedIn);
    // if (expire !== null && expire && !this.isRefreshing) {
    //   return await this.reloadAuth();
    // }

    return true;
  }

  private clear(): void {
    this.cookies.removeAll();
    this.storage.clear();
  }
}
