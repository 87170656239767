import { SupportedCoinsRequest } from './api/requests/supported-coins';

export type CompoundQueueField = 'offset' | 'last';

export interface ICompoundQueue {
  offset?: number;
  last?: number;
}

export interface ICompoundQueueRequest extends SupportedCoinsRequest, ICompoundQueue {}

export interface ICompoundFees {
  name: string;
  data: IFees[];
  isShowGraph?: boolean;
  id?: number;
  chart?: any;
};


export interface IFees {
  date: string | Date;
  gas: number;
  usd: number;
}