import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, of, tap } from 'rxjs';
import { MD5 } from 'crypto-js';
import { EnvironmentType } from '../../coin-depo-earn-calc/src/environments/environment.interface';
import { LanguageStateService } from './language-state.service';

export class CustomTranslateHttpLoader extends TranslateHttpLoader {
  private httpClient: HttpClient;

  constructor(
    http: HttpClient,
    private type: 'dashboard' | 'calculator',
    private crowdlId: string,
    private lanuage: LanguageStateService,
    prefix: string = './assets/i18n/',
    suffix: string = '.json',
    private env: EnvironmentType = 'dev',
  ) {
    super(http, prefix, suffix);
    this.httpClient = http;
  }

  override getTranslation(lang: string | undefined): Observable<any> {
    if (lang === 'only-key') {
      return of({});
    }
    if (lang === 'undefined' || lang === undefined) {
      lang = 'en';
    }
    if (localStorage.getItem('translate-source') === 'crowdl') {
      const timestamp = Date.now();

      return this.httpClient.get(
        `https://api.crowdl.io/${this.crowdlId}/translations`,
        {
          params: {
            api_key: 'tkLEF5rdayPUxR7PkkVfzqWgWE97PUA8',
            timestamp,
            locale: lang,
            include_missing: false,
            format: 'hierarchical-json',
            hash: MD5(
              timestamp + 'FWkbZnLo1oIH3KEw0CnmQ22hvn56o3vs'
            ).toString(),
          },
        }
      ).pipe(
        catchError(() => of({})),
        tap(() => this.lanuage.setLoadedLanguage())
      );
    }
    if (localStorage.getItem('translate-source') === 'local') {
      return super.getTranslation(lang).pipe(
        catchError(() => of({})),
        tap(() => this.lanuage.setLoadedLanguage())
      );
    }
    let host = 'https://lang.coindepo.io';
    if (this.env === 'prod') {
      host = 'https://lang.coindepo.com';
    }
    return this.httpClient.get(`${host}/${this.type}/${lang}${this.suffix}`).pipe(
      catchError(() => of({})),
      tap(() => this.lanuage.setLoadedLanguage())
    );
  }
}
