import { get } from 'lodash';
import { CookieService } from 'ngx-cookie';
import { map, Observable, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { URL_MAP } from '@core/http';
import { ApiResponse } from '@core/interfaces';
import { StorageService } from '@core/services';
import { environment } from '@env/environment';
import { toFormData } from '@shared/utils';
import { ServerNotRespondingService } from '@services/server-not-responding.service';

export class BaseApiService {
  protected baseUrl = environment.serverUrl;
  protected serviceName!: string;
  protected cookies!: CookieService;
  protected http!: HttpClient;
  protected router!: Router;
  protected storage!: StorageService;
  protected serverNotRespondingService!: ServerNotRespondingService;

  constructor() {}

  protected processRequest<I, O extends ApiResponse>(
    pathParam: keyof typeof URL_MAP,
    data: I
  ): Observable<O> {
    const dataWithClickId: any = { ...data };
    if (localStorage.getItem('click_id') && dataWithClickId.client_token) {
      dataWithClickId.id = localStorage.getItem('click_id');
    }
    if (localStorage.getItem('ga_utm')) {
      dataWithClickId.ga_utm = localStorage.getItem('ga_utm');
    }
    return this.http
      .post<any>(this.getUrl(pathParam), toFormData(dataWithClickId))
      .pipe(
        tap({
          next: (res: O): void => {
            console.log(
              `${this.serviceName} processRequest`,
              pathParam,
              data,
              res
            );
            if (res.status === 'success') {
              if (dataWithClickId.id && dataWithClickId.client_token) {
                localStorage.removeItem('click_id');
              }
              if (dataWithClickId.ga_utm && dataWithClickId.client_token) {
                localStorage.removeItem('ga_utm');
              }
            }
            if (res.status !== 'success') {
              this.checkForRelogin(res.msg as string, res?.code);
            }
            this.serverResponding();
          },
          error: (err: any) => {
            if (err.status === 0 || err.status === 502) {
              this.serverNotResponding();
            } else {
              this.serverResponding();
            }
            if (get(err, 'error.status', null) === 'error') {
              this.checkForRelogin(err.error.msg, err.error.code);
            }
          },
        })
      );
  }

  protected processFileRequest<I extends ApiResponse>(
    pathParam: keyof typeof URL_MAP,
    data: I
  ): Observable<any> {
    return this.http
      .post(this.getUrl(pathParam), toFormData(data), {
        responseType: 'text',
      })
      .pipe(
        tap({
          error: (err: any) => {
            if (get(err, 'error.status', null) === 'error') {
              this.checkForRelogin(err.error.msg, err.error.code);
            }
          },
        }),
        map((text: string) => new Blob([text], { type: 'text/plain' }))
      );
  }

  protected processBlobRequest<I extends ApiResponse>(
    pathParam: keyof typeof URL_MAP,
    data: I
  ): Observable<any> {
    return this.http
      .post(this.getUrl(pathParam), toFormData(data), {
        responseType: 'blob',
      })
      .pipe(
        tap({
          error: (err: any) => {
            if (get(err, 'error.status', null) === 'error') {
              this.checkForRelogin(err.error.msg, err.error.code);
            }
          },
        })
      );
  }

  protected getUrl(urlPart: keyof typeof URL_MAP): string {
    const path = get(URL_MAP, urlPart);
    return `${this.baseUrl}${path}`;
  }

  protected checkForRelogin(msg: string, code?: string): void {
    if (
      code &&
      (code === 'RELOGIN_REQUIRED' ||
        code === 'WRONG_PASSWORD' ||
        code === 'USER_NOT_ACTIVE' ||
        (code && code === 'AUTH_ERROR'))
    ) {
      this.cookies.removeAll();
      this.storage.clear();
      this.router.navigate(['/', 'auth', 'login']);
    }
  }

  private serverNotResponding() {
    if (!this.serverNotRespondingService.hasErrorSnapshot()) {
      this.serverNotRespondingService.enableFullscreenError();
    }
  }

  private serverResponding() {
    if (this.serverNotRespondingService.hasErrorSnapshot()) {
      this.serverNotRespondingService.disableFullscreenError();
    }
  }
}
