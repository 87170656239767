import { Injectable } from '@angular/core';

@Injectable()
export class LanguageStateService {
  private isLoadedLanguage: boolean = false;

  constructor() {}

  setLoadedLanguage() {
    this.isLoadedLanguage = true;
  }

  get getLanguageState () {
    return this.isLoadedLanguage;
  }
}
