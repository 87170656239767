import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { DepositPlansRequest, ExtendedApiResponse } from '@core/interfaces';

import { DepositPlansResponse } from '@core/interfaces';
import { StorageService } from '@core/services';
import { BaseApiService } from './base.service';
import { BaseApiRequest } from '@interfaces/api/requests/base-request';
import { DepositPlansAlt } from '../../../../../../coin-depo-lib/interfaces/deposit-plans-alt';
import { ServerNotRespondingService } from '@services/server-not-responding.service';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService extends BaseApiService {
  override serviceName = 'CommonApiService';

  constructor(
    protected override http: HttpClient,
    protected override cookies: CookieService,
    protected override router: Router,
    protected override storage: StorageService,
    protected override serverNotRespondingService: ServerNotRespondingService
  ) {
    super();
  }

  getDepositPlans(
    query: DepositPlansRequest
  ): Observable<ExtendedApiResponse<DepositPlansResponse>> {
    return this.processRequest<
      DepositPlansRequest,
      ExtendedApiResponse<DepositPlansResponse>
    >('depositDropdown', query);
  }

  getDepositPlansAlt(
    query: BaseApiRequest
  ): Observable<ExtendedApiResponse<DepositPlansAlt>> {
    return this.processRequest<
      BaseApiRequest,
      ExtendedApiResponse<DepositPlansAlt>
    >('depositPlans', query);
  }

  getDepositPlansNew(
    query: DepositPlansRequest
  ): Observable<ExtendedApiResponse<DepositPlansResponse>> {
    return this.processRequest<
      DepositPlansRequest,
      ExtendedApiResponse<DepositPlansResponse>
    >('newDepositDropdown', query);
  }
}
