import { omit } from 'lodash';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from '@core/http';

import {
  ApiResponse,
  BalanceDto,
  BalancesRequest,
  BalancesResponse,
  CreateDepositAccountRequest,
} from '@core/interfaces';

import { AuthService } from '@auth/services';
import {
  CurrencyService,
  CurrencyType,
  StorageService,
  ToastService,
} from '@core/services';
import { CommonBalancesService } from '@interfaces/CommonBalancesService';

@Injectable()
export class BalancesService implements CommonBalancesService {
  private balances$ = new BehaviorSubject<BalanceDto[]>(
    this.storage.get('balances', [])
  );
  cachedBalances$ = this.balances$.asObservable();

  private totalBalance$ = new BehaviorSubject<number>(
    this.storage.get('totalBalance', 0)
  );
  cachedTotalBalance$ = this.totalBalance$.asObservable();

  private availableCreditLimit$ = new BehaviorSubject<number>(
    this.storage.get('availableCreditLimit', 0)
  );
  cachedAvailableCreditLimit$ = this.availableCreditLimit$.asObservable();

  private creditLineLimit$ = new BehaviorSubject<number>(
    this.storage.get('creditLineLimit', 0)
  );
  cachedCreditLineLimit$ = this.creditLineLimit$.asObservable();

  private earnedReferrals$ = new BehaviorSubject<number>(
    this.storage.get('earnedReferrals', 0)
  );
  cachedearnedReferrals$ = this.earnedReferrals$.asObservable();

  constructor(
    private auth: AuthService,
    private balancesApi: ApiService,
    private cookies: CookieService,
    private router: Router,
    private storage: StorageService,
    private toast: ToastService,
    private currencyService: CurrencyService
  ) {}

  updateBalances(
    currency?: CurrencyType,
    waiteUntil?: Observable<any>
  ): Observable<BalancesResponse> {
    const userData = this.auth.userData;
    const data: BalancesRequest = {
      parent_id: this.auth.parentId,
      client_token: this.auth.accessToken,
      username: userData.email as string,
      user_id: userData.id,
      currency: currency || this.currencyService.currentCurrency,
      cummulative: true,
    };

    return this.balancesApi.getBalances(data).pipe(
      map((res) => omit(res, ['parent_id', 'status', 'msg'])),
      tap((res: BalancesResponse) => {
        if (waiteUntil) {
          waiteUntil.subscribe(() => {
            this.setStateFromResponse(res);
          });
        } else {
          this.setStateFromResponse(res);
        }
      })
    );
  }

  changeAccountVisibility(
    planId: string,
    walletName: string,
    visible = 1
  ): Observable<true | string> {
    const userData = this.auth.userData;

    const data: CreateDepositAccountRequest = {
      client_token: this.auth.accessToken,
      deposit_plan_id: parseInt(planId, 10),
      username: userData.email,
      visible,
      wallet_name: walletName,
    };

    return this.balancesApi.setAccountVisibility(data).pipe(
      map((res: ApiResponse) => (res.status === 'success' ? true : res.msg)),
      catchError((err: HttpErrorResponse) => of(err.error.msg))
    );
  }

  private setStateFromResponse(res: BalancesResponse) {
    this.setBalances(res.balances);
    this.setTotalBalance(res.total_balance);
    this.setAvailableCreditLimit(res.available_credit_limit);
    this.setCreditLineLimit(res.credit_line_limit);
    this.setEarnedReferrals(res.earned_referrals);
  }

  private setEarnedReferrals(earnedReferrals: number) {
    this.earnedReferrals$.next(earnedReferrals || 0);
    this.storage.set('earnedReferrals', earnedReferrals);
  }

  private setCreditLineLimit(creditLineLimit: number) {
    this.creditLineLimit$.next(creditLineLimit);
    this.storage.set('creditLineLimit', creditLineLimit);
  }

  private setAvailableCreditLimit(availableCreditLimit: number) {
    this.availableCreditLimit$.next(availableCreditLimit);
    this.storage.set('availableCreditLimit', availableCreditLimit);
  }

  private setTotalBalance(totalBalance: number) {
    this.totalBalance$.next(totalBalance);
    this.storage.set('totalBalance', totalBalance);
  }

  private setBalances(balances: BalanceDto[]) {
    this.balances$.next(balances);
    this.storage.set('balances', balances);
  }
}
