import { distinctUntilChanged, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Component, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

import { AuthService } from '@auth/services';
import { setTheme } from 'ngx-bootstrap/utils';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale, enGbLocale, ukLocale } from 'ngx-bootstrap/locale';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

defineLocale('en-uk', enGbLocale);
defineLocale('uk', ukLocale);
defineLocale('es', esLocale);

export const libLang: any = {
  bootstrap: {
    en: 'en-uk',
    uk: 'uk',
    ru: 'ru',
    'es-419': 'es',
  },
  moment: {
    en: 'en',
    uk: 'uk',
    ru: 'ru',
    'es-419': 'es',
  },
};

@Component({
  selector: 'cd-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(
    public auth: AuthService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private localeService: BsLocaleService,
    private translateService: TranslateService
  ) {
    if (localStorage.getItem('language') === 'en-US') {
      localStorage.setItem('language', 'en');
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.setLocale = (lang = 'en') => {
      this.translateService.use(lang);
      localStorage.setItem('language', lang);
      this.localeService.use(libLang.bootstrap[lang]);
    };
    this.translateService.use(localStorage.language || 'en');
    this.localeService.use(libLang.bootstrap[localStorage.language || 'en']);
    setTheme('bs4');
    const params = new HttpParams({ fromString: window.location.search });
    if (params.get('id')) {
      localStorage.setItem('click_id', params.get('id') as string);
    }
    if (params.get('ga_utm_cookie')) {
      localStorage.setItem('ga_utm', params.get('ga_utm_cookie') as string);
    }
    if (params.get('clickid')) {
      const clickId = {
        clickid: params.get('clickid'),
        campaign: params.get('campaign'),
        trafficsource: params.get('trafficsource'),
        user_id: params.get('user_id'),
      };
      localStorage.setItem(
        'click_id',
        encodeURIComponent(btoa(JSON.stringify(clickId)))
      );
    }
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged(),
        filter((e): e is NavigationEnd => e instanceof NavigationEnd)
      )
      .subscribe((e: NavigationEnd) => {
        if (e.url.startsWith('/auth')) {
          if (!this.auth.isLoggedIn) {
            this.document.querySelector('body')?.classList.add('form-wrapper');
          } else {
            this.document.querySelector('body')?.classList.add('form-wrapper');
            if (e.url.startsWith('/auth/reset-password')) {
            } else {
              this.document
                .querySelector('body')
                ?.classList.remove('form-wrapper');
              this.router.navigate(['/']);
            }
          }
        } else if (e.url.startsWith('/admin')) {
          if (e.url.startsWith('/admin/login')) {
            this.document.querySelector('body')?.classList.add('form-wrapper');
          } else {
            this.document
              .querySelector('body')
              ?.classList.remove('form-wrapper');
          }
        } else {
          this.document.querySelector('body')?.classList.remove('form-wrapper');

          if (!this.auth.isLoggedIn) {
            this.router.navigate(['/', 'auth', 'login']);
          }
        }

        if (!e.url.includes('admin')) {
          if (e.url === '/') {
            document.title =  'CoinDepo-dashboard';
          } else if (e.url.includes('profile')) {
            document.title = `CoinDepo-${e.url.split('/')[2]}`;
          }  else {
            document.title = `CoinDepo-${e.url.split('/')[1]}`;
          }
        };        
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
