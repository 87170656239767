import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '@core';

@Injectable({
  providedIn: 'root',
})
export class ServerNotRespondingService {
  private hasError = new BehaviorSubject<boolean>(
    this.storage.get('server-not-responding', false)
  );
  hasError$ = this.hasError.asObservable();

  constructor(private storage: StorageService) {}

  hasErrorSnapshot() {
    return this.hasError.value;
  }

  enableFullscreenError() {
    this.hasError.next(true);
    this.storage.set('server-not-responding', true);
  }

  disableFullscreenError() {
    this.hasError.next(false);
    this.storage.set('server-not-responding', false);
  }
}
