import { findIndex, some } from 'lodash';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

function createValidator(currentValue: any, errType: string, errMsg?: string): ValidatorFn {
  const value = currentValue;

  return function validatorFn(ctrl: AbstractControl): ValidationErrors | null {
    if (ctrl && ctrl.value && ctrl.value === value) {
      return { [errType]: errMsg };
    }

    return null;
  }
}
export const addError = (ctrl: AbstractControl, errType: string, errMsg?: string): void => {
  let validators: ValidatorFn | ValidatorFn[] = (ctrl as any)._rawValidators;

  if (typeof validators === 'function') {
    validators = [validators];
  }

  const exists = some(validators, v => v.name === errType);

  if (exists) {
    return;
  }

  ctrl.addValidators(createValidator(ctrl.value, errType, errMsg));
};

export const clearError = (input: HTMLElement) => {
  input.classList.remove('is-invalid');
  input.parentElement?.classList.remove('in-invalid');
  const parent = closest(input, 'form-control-input')?.querySelector('.invalid-feedback');

  if (parent) {
    parent.classList.add('hidden');
  }
};

export const closest = (input: HTMLElement, className: string): HTMLElement | null => {
  let parent = input.parentElement;

  while (parent && !parent.classList.contains(className)) {
    if (parent.parentElement) {
      parent = parent.parentElement;
    } else {
      break;
    }
  }

  if (parent && parent.classList.contains(className)) {
    return parent;
  }

  return null;
};

export const removeError = (ctrl: AbstractControl, errType: string, errMsg?: string): void => {
  let validators: ValidatorFn | ValidatorFn[] = (ctrl as any)._rawValidators;

  if (!Array.isArray(validators) && validators.name === errType) {
    ctrl.clearValidators();
  } else if (Array.isArray(validators)) {
    const idx = findIndex(validators, v => v.name === errType);

    if (idx === -1) {
      return;
    }

    validators.splice(idx, 1);
    ctrl.setValidators(validators);
  }
};

export const showError = (input: HTMLElement, msg: string): void => {
  const errEl = triggerError(input);

  if (errEl) {
    errEl.innerHTML = msg;
  }
};

export const triggerError = (input: HTMLElement): HTMLElement | null | undefined => {
  input.classList.add('is-invalid');
  input.parentElement?.classList.add('in-invalid');
  input.parentElement?.classList.remove('chosen');

  const errEl = closest(input, 'form-control-input')?.querySelector<HTMLElement>('.invalid-feedback');

  if (errEl) {
    errEl.classList.remove('hidden');
  }

  return errEl;
};


export default {
  addError,
  clearError,
  closest,
  removeError,
  showError,
  triggerError
};
