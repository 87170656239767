<div class="error-wrapper error-wrapper-page">
  <div class="wrapper">

    <div class="content">
      <div class="main">

        <div class="form-wrapper-content">

          <a class="form-wrapper-header" href="/">
            <span class="logo"></span>
            <span class="form-wrapper-header-txt">CoinDepo</span>
          </a>  <!-- .form-wrapper-header -->
          <div class="form-wrapper-body">
            <div>
              <h3>Error 404</h3>
              <p>Sorry! The page you’re looking for cannot be found.</p>
              <a routerLink="/" class="btn btn-secondary btn-lg  btn-min-w">Go to CoinDepo</a>
            </div>
            <div>
              <img class="img-form-wrapper-body" src="/assets/images/error.png" alt="">
            </div>
          </div>

        </div>


      </div>

    </div>


  </div>


</div>
