/* eslint-disable no-underscore-dangle */
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { CommonApiService } from '@core/http';

import {
  DepositPlanNewDto, DepositPlanDto, DepositPlansRequest, DepositPlansResponse,
  ExtendedApiResponse,
} from '@core/interfaces';

import { AuthService } from '@auth/services';
import { StorageService } from '@core/services';

@Injectable({
  providedIn: 'root'
})
export class CommonService implements OnDestroy {
  private destroyed$ = new Subject<void>();

  get depositPlans(): DepositPlanDto[] {
    return this.storage.get('depositPlans');
  }

  set depositPlans(val: DepositPlanDto[]) {
    this.storage.set('depositPlans', val);
  }

  get depositPlansNew(): DepositPlanNewDto[] {
    return this.storage.get('depositPlansNew');
  }

  set depositPlansNew(val: DepositPlanNewDto[]) {
    this.storage.set('depositPlansNew', val);
  }


  constructor(
    private auth: AuthService,
    private storage: StorageService,
    private commonApi: CommonApiService
  ) {
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getDepositPlans(): Observable<DepositPlanDto[] | string> {
    const userData = this.auth.userData;

    const query: DepositPlansRequest = {
      client_token: this.auth.accessToken,
      username: userData.username || userData.email
    };

    return this.commonApi.getDepositPlans(query)
      .pipe(
        map((res: ExtendedApiResponse<DepositPlansResponse>) => (res.status === 'success' && res.plans)
          ? res.plans
          : res.msg
        ),
        catchError((err: HttpErrorResponse) => of(err.error.msg)),
        tap((res: DepositPlanDto[] | string) => {
          if (typeof res !== 'string') {
            this.depositPlans = res;
          }
        })
      );
  }

  getDepositPlansNew(): Observable<DepositPlanNewDto[] | string> {
    const userData = this.auth.userData;

    const query: DepositPlansRequest = {
      client_token: this.auth.accessToken,
      username: userData.username || userData.email
    };

    return this.commonApi.getDepositPlansNew(query)
      .pipe(
        map((res: ExtendedApiResponse<DepositPlansResponse>) => (res.status === 'success' && res.plans)
          ? res.plans
          : res.msg
        ),
        catchError((err: HttpErrorResponse) => of(err.error.msg)),
        tap((res: DepositPlanNewDto[] | string) => {
          if (typeof res !== 'string') {
            this.depositPlansNew = res;
          }
        })
      );
  }
}
