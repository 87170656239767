import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(
    private toastr: ToastrService
  ) {}

  warning(text: string, title?: string): void {
    this.toastr.warning(text, title);
  }

  info(text: string, title?: string): void {
    this.toastr.info(text, title);
  }

  success(text: string, title?: string): void {
    this.toastr.success(text, title);
  }

  error(text: string, title?: string): void {
    this.toastr.error(text, title);
  }

  show(text: string, title?: string): void {
    this.toastr.show(text, title);
  }
}
