import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  AccountStatsRequest,
  AccountStatsResponse,
  ApiResponse,
  BalancesRequest,
  BalancesResponse,
  CreateDepositAccountRequest,
  DepositAssetsRequest,
  DepositAssetsResponse,
  ExtendedApiResponse,
  SupportedCoinsRequest,
  SupportedCoinsResponse,
} from '@core/interfaces';

import { StorageService } from '@core/services';
import { BaseApiService } from './base.service';
import { NewDepositAssetsRequest } from '@interfaces/api/requests/new-deposit-assets';
import { NewDepositAssetsResponse } from '../../../../../../coin-depo-lib/interfaces/new-deposit-assets';
import { UserWalletRequest } from '@interfaces/api/requests/user-wallet';
import { TransactionsRequest } from '@interfaces/api/requests/transaction';
import { TransactionsResponse } from '@interfaces/api/responses/transaction';
import { BaseApiRequest } from '@interfaces/api/requests/base-request';
import { CountriesResponse } from '@interfaces/api/responses/countries';
import { SourceOfFoundsResponse } from '@interfaces/api/responses/source-of-founds';
import { SupportedDocumentsRequest } from '@interfaces/api/requests/supported-documents';
import { SupportedDocumentsResponse } from '@interfaces/api/responses/supported-documents';
import { ServerNotRespondingService } from '@services/server-not-responding.service';
import { CreatePromoCode, RequestPromoCode, ResponcePromoCode, UpdatePromoCode } from '@core/interfaces/code';
import { ICompoundFees, ICompoundQueueRequest } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseApiService {
  override serviceName = 'ApiService';

  constructor(
    protected override http: HttpClient,
    protected override cookies: CookieService,
    protected override router: Router,
    protected override storage: StorageService,
    protected override serverNotRespondingService: ServerNotRespondingService
  ) {
    super();
  }

  getAccountStats(
    data: AccountStatsRequest
  ): Observable<ExtendedApiResponse<AccountStatsResponse>> {
    return this.processRequest<
      AccountStatsRequest,
      ExtendedApiResponse<AccountStatsResponse>
    >('accountStats', data);
  }

  getBalances(
    data: BalancesRequest
  ): Observable<ExtendedApiResponse<BalancesResponse>> {
    return this.processRequest<
      BalancesRequest,
      ExtendedApiResponse<BalancesResponse>
    >('balances', data);
  }

  getSupportedCoins(
    data: SupportedCoinsRequest
  ): Observable<ExtendedApiResponse<SupportedCoinsResponse>> {
    return this.processRequest<
      SupportedCoinsRequest,
      ExtendedApiResponse<SupportedCoinsResponse>
    >('supportedCoins', data);
  }

  getAdminSupportedCoins(
    data: SupportedCoinsRequest
  ): Observable<ExtendedApiResponse<SupportedCoinsResponse>> {
    return this.processRequest<
      SupportedCoinsRequest,
      ExtendedApiResponse<SupportedCoinsResponse>
    >('adminSupportedCoins', data);
  }

  getAdminCompoundFees(
    data: ICompoundQueueRequest
  ): Observable<ExtendedApiResponse<ICompoundFees[]>> {
    return this.processRequest<
    ICompoundQueueRequest,
    ExtendedApiResponse<ICompoundFees[]>
    >('adminCompoundFees', data);
  }


  getDepositAssets(
    data: DepositAssetsRequest
  ): Observable<ExtendedApiResponse<DepositAssetsResponse>> {
    return this.processRequest<
      DepositAssetsRequest,
      ExtendedApiResponse<DepositAssetsResponse>
    >('assetDropdown', data);
  }

  getCountries(
    data: BaseApiRequest & any
  ): Observable<ExtendedApiResponse<CountriesResponse>> {
    return this.processRequest<
      BaseApiRequest,
      ExtendedApiResponse<CountriesResponse>
    >('getCountries', data);
  }

  getAllCountries(
    data: BaseApiRequest & any
  ): Observable<ExtendedApiResponse<CountriesResponse>> {
    return this.processRequest<
      BaseApiRequest,
      ExtendedApiResponse<CountriesResponse>
    >('getAllCountries', data);
  }

  adminGetUsers(
    data: BaseApiRequest & any
  ): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<BaseApiRequest, ExtendedApiResponse<any>>(
      'adminGetUsers',
      data
    );
  }

  adminGetUsersFile(
    data: BaseApiRequest & any
  ): Observable<ExtendedApiResponse<any>> {
    return this.processBlobRequest<any>('adminGetUsers', data);
  }

  adminGetWaitlist(
    data: BaseApiRequest & any
  ): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<BaseApiRequest, ExtendedApiResponse<any>>(
      'adminGetWaitlist',
      data
    );
  }

  adminUpdateTranslate(
    data: BaseApiRequest & any
  ): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<BaseApiRequest, ExtendedApiResponse<any>>(
      'adminUpdateTranslate',
      data
    );
  }

  adminGetWaitlistCsv(
    data: BaseApiRequest & any
  ): Observable<ExtendedApiResponse<any>> {
    return this.processFileRequest('adminGetWaitlist', data);
  }

  adminSetCoinsOrder(
    data: BaseApiRequest & any
  ): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<BaseApiRequest, ExtendedApiResponse<any>>(
      'adminSetCoinsOrder',
      data
    );
  }

  adminGetNoFunds(
    data: BaseApiRequest & any
  ): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<BaseApiRequest, ExtendedApiResponse<any>>(
      'adminGetNoFunds',
      data
    );
  }

  adminResendNoFunds(
    data: BaseApiRequest & any
  ): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<BaseApiRequest, ExtendedApiResponse<any>>(
      'adminResendNoFunds',
      data
    );
  }

  getAdminCreditLine(data: any) {
    return this.processRequest<BaseApiRequest, ExtendedApiResponse<any>>(
      'adminGetCreditLines',
      data
    );
  }

  adminUpdateUser(
    data: BaseApiRequest & any
  ): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<BaseApiRequest, ExtendedApiResponse<any>>(
      'adminUpdateUser',
      data
    );
  }

  adminGetPhoto(
    data: BaseApiRequest & any
  ): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<BaseApiRequest, ExtendedApiResponse<any>>(
      'adminGetPhoto',
      data
    );
  }

  getAdminTransactions(
    data: BaseApiRequest & any
  ): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<BaseApiRequest, ExtendedApiResponse<any>>(
      'adminGetTransactions',
      data
    );
  }

  addAdmin(data: any): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<BaseApiRequest, ExtendedApiResponse<any>>(
      'addAdmin',
      data
    );
  }

  updateAdminCreditLines(data: any) {
    return this.processRequest<BaseApiRequest, ExtendedApiResponse<any>>(
      'adminCreateCreditLine',
      data
    );
  }

  getAdminTransactionsCsv(data: any): Observable<ExtendedApiResponse<any>> {
    return this.processBlobRequest('adminGetTransactions', data);
  }

  adminGetReports(data: any): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<BaseApiRequest, ExtendedApiResponse<any>>(
      'adminGetReports',
      data
    );
  }

  adminGetReportDetails(data: any): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<BaseApiRequest, ExtendedApiResponse<any>>(
      'adminGetReportDetails',
      data
    );
  }

  getSourceOfFunds(
    data: BaseApiRequest
  ): Observable<ExtendedApiResponse<SourceOfFoundsResponse>> {
    return this.processRequest<
      BaseApiRequest,
      ExtendedApiResponse<SourceOfFoundsResponse>
    >('getSourceOfFunds', data);
  }

  getTransactionsList(
    data: TransactionsRequest
  ): Observable<ExtendedApiResponse<TransactionsResponse>> {
    return this.processRequest<
      TransactionsRequest,
      ExtendedApiResponse<TransactionsResponse>
    >('transactionsList', data);
  }

  getTransactionsCsvList(data: any): Observable<any> {
    return this.processBlobRequest<any>('transactionsList', data);
  }

  cancelWithdrawal(data: any): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'cancelWithdrawal',
      data
    );
  }

  getUserWallet(data: UserWalletRequest): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<UserWalletRequest, ExtendedApiResponse<any>>(
      'userWallet',
      data
    );
  }

  getPartnerBalance(data: any): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'getPartnerBalance',
      data
    );
  }

  withdrawFundsRequest(data: any): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'withdrawFundsRequest',
      data
    );
  }

  withdrawFundsConfirmation(data: any): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'withdrawFundsConfirmation',
      data
    );
  }

  internalTransfer(data: any): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'internalTransfer',
      data
    );
  }

  createDepositPlan(data: any): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'createDepositPlan',
      data
    );
  }

  resendWithdrawalCode(data: any): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'resendWithdrawalCode',
      data
    );
  }

  getNewDepositAssets(
    data: NewDepositAssetsRequest
  ): Observable<ExtendedApiResponse<NewDepositAssetsResponse>> {
    return this.processRequest<
      NewDepositAssetsRequest,
      ExtendedApiResponse<NewDepositAssetsResponse>
    >('newAssetDropdown', data);
  }

  setAccountVisibility(
    data: CreateDepositAccountRequest
  ): Observable<ApiResponse> {
    return this.processRequest<CreateDepositAccountRequest, ApiResponse>(
      'accountVisibility',
      data
    );
  }

  getSupportedDocuments(data: SupportedDocumentsRequest) {
    return this.processRequest<
      SupportedDocumentsRequest,
      ExtendedApiResponse<SupportedDocumentsResponse>
    >('supportedDocuments', data);
  }

  getLoan(data: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>('getLoan', data);
  }

  repayLoan(data: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'repayLoan',
      data
    );
  }

  updateAssetStatus(data: {
    client_token: string;
    coin_id: number;
    username: string;
    status: number;
  }) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'updateAssetStatus',
      data
    );
  }

  setLimits(data: {
    min_gas_balance?: number | undefined;
    client_token: string;
    max_compound_fee?: number | undefined;
    coin_id: number | undefined;
    action?: 0 | 1 | 2;
    username: string;
    amount?: number;
  }) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'setLimits',
      data
    );
  }

  resetCompoundCounters(data: {
    client_token: string;
    username: string;
    coin_id: number;
  }) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'resetCompoundCounters',
      data
    );
  }

  adminGetReferralsList(param: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'adminGetReferralsList',
      param
    );
  }

  adminUpdatePartnerStatus(param: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'adminUpdatePartnerStatus',
      param
    );
  }

  adminSetUserStatus(param: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'adminSetUserStatus',
      param
    );
  }

  adminUserWalletRebalance(param: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'adminUserWalletRebalance',
      param
    );
  }

  getAdminAccountStats(param: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'adminAccountStats',
      param
    );
  }

  adminGetBalances(param: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'adminGetBalances',
      param
    );
  }

  getAdminUserStatus(param: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'adminUserStatus',
      param
    );
  }

  adminUpdateUserEmail(param: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'adminUpdateUserEmail',
      param
    );
  }

  adminGetPromoCodes(param: RequestPromoCode) {
    return this.processRequest<RequestPromoCode, ResponcePromoCode>(
      'getPromoCodes',
      param
    );
  }

  adminGetCsvPromoCodes(param: ApiResponse & RequestPromoCode): Observable<any> {
    return this.processFileRequest('getPromoCodes', param);
  }

  adminAddPromoCode(param: CreatePromoCode) {
    return this.processRequest<CreatePromoCode, ApiResponse>(
      'addPromoCode',
      param
    );
  }

  adminUpdatePromoCode(param: UpdatePromoCode) {
    return this.processRequest<UpdatePromoCode, ApiResponse>(
      'updatePromoCode',
      param
    );
  }
}
