import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';

import { AuthService } from '@auth/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  public canActivate(): Observable<boolean> | Promise<boolean>  |boolean {
    const authorized = this.authService.isLoggedIn;

    if (!authorized) {
      return this.router
        .navigate(['/auth/login'])
        .then(() => authorized);
    }

    return authorized;
  }

  public canActivateChild(): Observable<boolean> | Promise<boolean>  |boolean {
    const authorized = this.authService.isLoggedIn;

    if (!authorized) {
      return this.router
        .navigate(['/auth/login'])
        .then(() => authorized);
    }

    return authorized;
  }
}
