export const URL_MAP = {
  accountNames: 'get_account_names',
  accountStats: 'get_account_stats',
  accountVisibility: 'account_visibility',
  assetDropdown: 'get_assets_dropdown',
  getCountries: 'get_countries',
  getAllCountries: 'get_all_countries',
  setLanguage: 'set_language',
  adminSetCoinsOrder: 'admin/set_coins_order',
  adminGetUsers: 'admin/get_users',
  adminGetWaitlist: 'admin/get_waitlist',
  adminUpdateTranslate: 'admin/update_translations',
  adminGetNoFunds: 'admin/get_no_funds',
  adminResendNoFunds: 'admin/resend_no_funds',
  adminGetCreditLines: 'admin/get_credit_lines',
  adminCreateCreditLine: 'admin/create_credit_line',
  adminGetReports: 'admin/get_reports',
  adminGetReportDetails: 'admin/get_report_details',
  adminGetPhoto: 'admin/get_photo',
  adminGetTransactions: 'admin/get_transactions',
  addAdmin: 'admin/add_admin',
  updateAssetStatus: 'admin/update_asset_status',
  setLimits: 'admin/set_limits',
  resetCompoundCounters: 'admin/reset_compound_counters',
  adminGetReferralsList: 'admin/get_referrals_list',
  adminUpdatePartnerStatus: 'admin/update_partner_status',
  adminSetUserStatus: 'admin/set_user_status',
  adminUserWalletRebalance: 'admin/user_wallet_rebalance',
  adminAccountStats: 'admin/get_account_stats',
  adminGetBalances: 'admin/get_balances',
  adminUserStatus: 'admin/user_stats',
  adminUpdateUserEmail: 'admin/update_user_email',
  adminUpdateUser: 'admin/update_info_user',
  getSourceOfFunds: 'get_source_of_funds',
  newAssetDropdown: 'get_new_deposit_dropdown',
  supportedDocuments: 'supported_documents',
  getLoan: 'get_loan',
  repayLoan: 'repay_loan',
  balances: 'get_balances',
  changePassword: 'change_password',
  updateDetails: 'update_details',
  verifyLogin: 'verify_login',
  setAvatar: 'set_avatar',
  createDepositPlan: 'create_deposit_plan',
  deleteUser: 'delete_user',
  delTotp: 'del_totp',
  changePasswordRequest: 'change_password_request',
  changePasswordConfirmation: 'change_password_confirmation',
  depositDropdown: 'get_deposit_dropdown',
  depositPlans: 'get_deposit_plans',
  details: 'details',
  internalTransfer: 'internal_transfer',
  lastIps: 'get_last_ips',
  login: 'login',
  logout: 'logout',
  newDepositDropdown: 'get_new_deposit_dropdown',
  register: 'register',
  verifyBonusCode: 'verify_promocode',
  resendCode: 'resend_code',
  resendConfirmationCode: 'resend_confirmation_code',
  resendWithdrawalCode: 'resend_withdrawal_code',
  resetPassword: 'reset_password',
  supportedCoins: 'supported_coins',
  adminSupportedCoins: 'admin/supported_coins',
  adminCompoundFees: 'admin/get_compound_fees',
  updateUserSettings: 'update_user_settings',
  totp: 'add_totp',
  transactionsList: 'get_transactions_list',
  cancelWithdrawal: 'cancel_withdrawal',
  userWallet: 'get_user_wallet',
  deleteUserCheck: 'delete_user_check',
  getPartnerBalance: 'get_partner_balance',
  verify: 'verify',
  waitList: 'join_waitlist',
  withdrawFundsConfirmation: 'withdraw_funds_confirmation',
  withdrawFundsRequest: 'withdraw_funds_request',
  sendRefCode: 'send_ref_code',
  getReferralsList: 'get_referrals_list',
  getPromoCodes: 'admin/get_promo_codes',
  getPromoCodeById: 'admin/get_promo_code',
  addPromoCode: 'admin/add_promo_code',
  updatePromoCode: 'admin/update_promo_code',
};
