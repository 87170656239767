import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export interface PasswordContains {
  upper: boolean;
  lower: boolean;
  num: boolean;
  special: boolean;
}

export const coinAmountMinimal =
  (minimal: number, ticker: string): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    if (+control.value < minimal) {
      return { coinAmountMinimal: { minimal, ticker } };
    }
    return null;
  };

export const coinAmountAvailable =
  (available: number): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {    
    if (Number(control.value.split(',').join('')) > available) {
      return { coinAmountAvailable: { available } };
    }
    return null;
  };

export const coinCheckZero =
  (): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    if (+control.value === 0) {
      return { coinAmountZero: true };
    }
    return null;
  };

export const coinAmountLimit =
  (limit: number): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    if (+control.value > limit) {
      return { coinAmountLimit: { limit } };
    }
    return null;
  };

export const isVerifyCodeError = (
  control: AbstractControl
): ValidationErrors | null => {
    if (control.errors?.CODE_NOT_VALID) {
      return { CODE_NOT_VALID: true };
    } else if (control.errors?.LIMIT_EXCEEDED) {
      return { LIMIT_EXCEEDED: true};
    }

    return null;
};

export const passwordCharsValidator = (
  control: AbstractControl
): ValidationErrors | null => {
  const check = checkPasswordContains(control.value);

  if (error3From4(check)) {
    return null;
  }

  return { passwordChars: true };
};

const error3From4 = (check: PasswordContains): boolean => {
  const countTrue = Object.values(check).reduce(
    (previousValue, currentValue) => previousValue + (currentValue ? 1 : 0),
    0
  );
  return countTrue >= 3;
};

export const checkPasswordContains = (password: string): PasswordContains => {
  const check: PasswordContains = {
    upper: false,
    lower: false,
    num: false,
    special: false,
  };

  if (/[A-Z]/.test(password)) {
    check.upper = true;
  }

  if (/[a-z]/.test(password)) {
    check.lower = true;
  }

  if (/[!"#$%&'()*+,./:;<=>?@[\]^_`{|}~-]/.test(password)) {
    check.special = true;
  }

  if (/[0-9]/.test(password)) {
    check.num = true;
  }
  return check;
};

export const equalToValidator =
  (ctrlName: string): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const form = control.root;
    const compare = form.get(ctrlName);

    if (compare) {
      if (control.value === compare.value) {
        return null;
      }

      return { equalTo: true };
    }

    return null;
  };

export const onlyNumbers = (
  control: AbstractControl
): ValidationErrors | null => {
  if (control.value.match(/^\d*$/)) {
    return null;
  } else {
    return { onlyNumbers: true };
  }
};
