import { Injectable } from '@angular/core';
import { StorageService } from '@services/storage.service';
import { BehaviorSubject } from 'rxjs';
import { Currency } from '@interfaces';

export type CurrencyType = 'USD' | 'EUR' | 'GBP';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  currentCurrency: CurrencyType = this.storage.get('currency', 'USD');
  private currentCurrencySubject$ = new BehaviorSubject<CurrencyType>(
    this.currentCurrency
  );
  currentCurrency$ = this.currentCurrencySubject$.asObservable();

  constructor(private storage: StorageService) {}

  setCurrency(currency: CurrencyType) {
    this.currentCurrency = currency;
    this.storage.set('currency', this.currentCurrency);
    this.currentCurrencySubject$.next(currency);
  }

  getCurrencies(): Currency[] {
    return [
      { symbol: '$', code: 'USD' },
      { symbol: '&euro;', code: 'EUR' },
      { symbol: '&pound;', code: 'GBP' },
    ];
  }
}
