import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  ApiResponse,
  ConfirmationCodeRequest,
  ExtendedApiResponse,
  LoginApiResponse,
  LoginRequest,
  LogoutRequest,
  RegisterApiResponse,
  RegisterRequest,
  ResetPasswordRequest,
  VerifyApiResponse,
  VerifyRequest,
  WaitListRequest,
  WaitListResponse,
} from '@core/interfaces';

import { BaseApiService } from './base.service';
import { StorageService } from '@core/services';
import { BaseApiRequest } from '@interfaces/api/requests/base-request';
import { UserDetailsResponse } from '@interfaces/api/responses/user-details';
import { ChangePasswordRequest } from '@interfaces/api/requests/change-password-request';
import {
  UpdateUserSettings,
  UpdateUserSettingsRequest,
} from '@interfaces/api/requests/update-user-settings';
import { ServerNotRespondingService } from '@services/server-not-responding.service';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService extends BaseApiService {
  override serviceName = 'UsersApiService';

  constructor(
    protected override http: HttpClient,
    protected override cookies: CookieService,
    protected override router: Router,
    protected override storage: StorageService,
    protected override serverNotRespondingService: ServerNotRespondingService
  ) {
    super();
  }

  login(data: LoginRequest): Observable<ExtendedApiResponse<LoginApiResponse>> {
    return this.processRequest<
      LoginRequest,
      ExtendedApiResponse<LoginApiResponse>
    >('login', data);
  }

  getLastIps(data: any): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<any, ExtendedApiResponse<any>>('lastIps', data);
  }

  canDeleteUser(
    data: any
  ): Observable<ExtendedApiResponse<{ can_delete: 0 | 1 }>> {
    return this.processRequest<any, ExtendedApiResponse<{ can_delete: 0 | 1 }>>(
      'deleteUserCheck',
      data
    );
  }

  sendRefCode(data: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'sendRefCode',
      data
    );
  }

  getReferrals(data: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'getReferralsList',
      data
    );
  }

  setLanguage(data: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'setLanguage',
      data
    );
  }

  logout(data: LogoutRequest): Observable<ApiResponse> {
    return this.processRequest<LogoutRequest, ApiResponse>('logout', data);
  }

  addTOTP(data: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>('totp', data);
  }

  delTOTP(data: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>('delTotp', data);
  }

  deleteUser(data: any) {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'deleteUser',
      data
    );
  }

  updateUserSettings(
    data: UpdateUserSettingsRequest
  ): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<
      UpdateUserSettingsRequest,
      ExtendedApiResponse<any>
    >('updateUserSettings', data);
  }

  register(
    data: RegisterRequest
  ): Observable<ExtendedApiResponse<RegisterApiResponse>> {
    return this.processRequest<
      RegisterRequest,
      ExtendedApiResponse<RegisterApiResponse>
    >('register', data);
  }

  verifyBonusCode(
    data: {['referral']: string}
  ): Observable<ApiResponse> {
    return this.processRequest<
    {['referral']: string},
    ApiResponse
    >('verifyBonusCode', data);
  }

  changePasswordRequest(data: any): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'changePasswordRequest',
      data
    );
  }

  changePasswordConfirmation(data: any): Observable<ExtendedApiResponse<any>> {
    return this.processRequest<any, ExtendedApiResponse<any>>(
      'changePasswordConfirmation',
      data
    );
  }

  getConfirmationCode(data: ConfirmationCodeRequest): Observable<ApiResponse> {
    return this.processRequest<ConfirmationCodeRequest, ApiResponse>(
      'resendConfirmationCode',
      data
    );
  }

  getDetails(
    data: BaseApiRequest
  ): Observable<ExtendedApiResponse<UserDetailsResponse>> {
    return this.processRequest<
      BaseApiRequest,
      ExtendedApiResponse<UserDetailsResponse>
    >('details', data);
  }

  verifyEmail(
    data: VerifyRequest
  ): Observable<ExtendedApiResponse<VerifyApiResponse>> {
    return this.processRequest<
      VerifyRequest,
      ExtendedApiResponse<VerifyApiResponse>
    >('verify', data);
  }

  resetPassword(data: ResetPasswordRequest): Observable<ApiResponse> {
    return this.processRequest<ResetPasswordRequest, ApiResponse>(
      'resetPassword',
      data
    );
  }

  changePassword(data: ChangePasswordRequest): Observable<ApiResponse> {
    return this.processRequest<any, ApiResponse>('changePassword', data);
  }

  waitListManage(
    data: WaitListRequest
  ): Observable<ExtendedApiResponse<WaitListResponse>> {
    return this.processRequest<
      WaitListRequest,
      ExtendedApiResponse<WaitListResponse>
    >('waitList', data);
  }

  updateUserDetails(data: any) {
    return this.processRequest<any, ApiResponse>('updateDetails', data);
  }

  setAvatar(data: any) {
    return this.processRequest('setAvatar', data);
  }

  sendEmailVerificationCode(data: any) {
    return this.processRequest('verifyLogin', data);
  }
}
