import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Injectable, OnDestroy } from '@angular/core';

import { User } from '@core/interfaces';
import { StorageService } from '@core/services';
import { Profile, SimpleService } from './common-profile-info';

export class UserService implements SimpleService<User> {
  constructor(
    private storage: StorageService
  ) { }

  loadData(): Observable<Partial<User>> {
    const userData = this.storage.get('userData');
    return of(userData);
  }
}

@Injectable()
export class UserProfileInfo extends Profile<Partial<User>, UserService> implements OnDestroy {
  protected override service!: UserService;
  private destroyed$ = new Subject<void>();

  constructor(service: UserService) {
    super(service);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  load(): void {
    this.loading$.next(true);

    this.service.loadData()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user: Partial<User>): void => {
        if (user) {
          this.loaded$.next(true);
          this.loading$.next(false);
          this.data$.next(user);
        }
      });
  }
}
