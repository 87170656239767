import { CookieModule } from 'ngx-cookie';
import { ToastrModule } from 'ngx-toastr';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { STORAGE_KEY } from '@core/interfaces';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxMaskModule } from 'ngx-mask';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import pack from 'package.json';
import { registerLocaleData } from '@angular/common';
import localeUA from '@angular/common/locales/ru-UA';
import localeEN from '@angular/common/locales/en';
import { NgModule } from '@angular/core';
import { CustomTranslateHttpLoader } from '../../../coin-depo-lib/shared/CustomTranslateHttpLoader';
import { environment } from '@env/environment';
import { LanguageStateService } from 'apps/coin-depo-lib/shared/language-state.service';

console.log(`%c Version: ${pack.version}`, 'color: blue');
registerLocaleData(localeUA, 'uk');
registerLocaleData(localeEN, 'en');

export const createTranslateLoader = (http: HttpClient, languageStateService: LanguageStateService) =>
  new CustomTranslateHttpLoader(
    http,
    'dashboard',
    '1942',
    languageStateService,
    './assets/i18n/',
    '.json',
    environment.env,
  );

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return params.key;
  }
}

@NgModule({
  bootstrap: [AppComponent],

  declarations: [AppComponent],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CookieModule.forRoot({ sameSite: 'strict' }),
    ToastrModule.forRoot({
      enableHtml: true,
      positionClass: 'toast-top-right',
      toastClass: 'cd-toast',
      closeButton: true,
      timeOut: 4000,
    }),
    AppRoutingModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: localStorage.language || 'en',
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
      useDefaultLang: false,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, LanguageStateService],
      },
    }),
  ],

  providers: [
    {
      provide: STORAGE_KEY,
      useValue: 'config_storage',
    },
    LanguageStateService
  ],
})
export class AppModule {}
