import { NgModule } from '@angular/core';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  exports: [
    AccordionModule,
    AlertModule,
    BsDropdownModule,
    ModalModule,
    PopoverModule,
    TabsModule,
    TooltipModule,
  ],

  imports: [
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
  ],
  providers: [{ provide: TooltipConfig, useValue: { placement: 'bottom' } }],
})
export class LocalBootstrapModule {}

export * from 'ngx-bootstrap/accordion';
export * from 'ngx-bootstrap/alert';
export * from 'ngx-bootstrap/dropdown';
export * from 'ngx-bootstrap/modal';
export * from 'ngx-bootstrap/popover';
export * from 'ngx-bootstrap/tabs';
export * from 'ngx-bootstrap/tooltip';
