import { get as loGet, omit, set } from 'lodash';

import { Inject, Injectable } from '@angular/core';

import { STORAGE_KEY } from '@core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private get storageKey(): string {
    return `CoinDepo_${this._key}`;
  }

  constructor(@Inject(STORAGE_KEY) private _key: string) {
    const storageVar = localStorage.getItem(this.storageKey);

    if (!storageVar) {
      localStorage.setItem(this.storageKey, JSON.stringify({}));
    }
  }

  get(key: string, def: any = undefined): any {
    const json = localStorage.getItem(this.storageKey);

    if (json) {
      try {
        const storage = JSON.parse(json);
        return loGet(storage, key, def);
      } catch (e) {
        console.log(e);
        return def;
      }
    }

    return def;
  }

  set(key: string, val: any): void {
    const json = localStorage.getItem(this.storageKey);

    if (json) {
      try {
        const storage = JSON.parse(json);
        set(storage, key, val);
        localStorage.setItem(this.storageKey, JSON.stringify(storage));
      } catch (e) {
        console.log(e);
      }

    }
  }

  remove(key: string): void {
    const json = localStorage.getItem(this.storageKey);

    if (json) {
      try {
        const storage = JSON.parse(json);
        const tmp = omit(storage, key);
        localStorage.setItem(this.storageKey, JSON.stringify(tmp));
      } catch (e) {
        console.log(e);
      }
    }
  }

  clear(): void {
    localStorage.setItem(this.storageKey, JSON.stringify({}));
  }
}
