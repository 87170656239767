import { shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { User } from '@core/interfaces';

export type SimpleService<T> = {
  loadData: () => Observable<Partial<T>>;
};

type ProfileInfo<M extends Partial<User>> = {
  loading$: BehaviorSubject<boolean>;
  loaded$: BehaviorSubject<boolean>;
  data$: BehaviorSubject<M | null>;
};

type ProfileModel = Partial<User>;
type ProfileService = SimpleService<Partial<User>>;

export abstract class Profile<M extends ProfileModel, S extends ProfileService> implements ProfileInfo<M> {
  loading$ = new BehaviorSubject<boolean>(false);
  loaded$ = new BehaviorSubject<boolean>(false);
  data$ = new BehaviorSubject<M | null>(null);

  sData$: Observable<M | null> = this.data$.asObservable().pipe(shareReplay(1));
  sLoaded$: Observable<boolean> = this.loaded$.asObservable().pipe(shareReplay(1));
  sLoading$: Observable<boolean> = this.loading$.asObservable().pipe(shareReplay(1));

  protected service: S;

  constructor(service: S) {
    this.service = service;
  }

  abstract load(): void;

  clear(): void {
    this.loading$.next(false);
    this.loaded$.next(false);
    this.data$.next(null);
  }
}
