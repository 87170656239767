import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '@core';

@Injectable({
  providedIn: 'root',
})
export class UserTypeService {
  private userType$ = new BehaviorSubject<'Individual' | 'Partner'>(
    this.storage.get('user_type', 'Individual')
  );
  cachedUserType$ = this.userType$.asObservable();

  constructor(private storage: StorageService) {}

  userTypeSnapshot() {
    return this.userType$.value;
  }

  setUserType(userType: 'Individual' | 'Partner') {
    this.userType$.next(userType);
    this.storage.set('user_type', userType);
  }
}
