import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { UserDetailsService } from '@services/user-details.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserTypeService } from '@services/user-type.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerGuard implements CanActivate, CanActivateChild {
  constructor(
    private userTypeService: UserTypeService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.userTypeService.cachedUserType$.pipe(
      map((userType) => {
        if (userType === 'Individual') {
          return true;
        } else {
          return this.router.createUrlTree(['account'], {
            relativeTo: this.route,
          });
        }
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.userTypeService.cachedUserType$.pipe(
      map((userType) => {
        if (userType === 'Individual') {
          return true;
        } else {
          return this.router.createUrlTree(['account'], {
            relativeTo: this.route,
          });
        }
      })
    );
  }
}
